body {
  font-family: Arial, sans-serif;
  color: #fff;
  margin: 0;
  padding: 0;
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url(../images/plaza.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 240px;
  height: 72px;
}

/* Header styles */
header {
  margin-top: 6rem;
  padding: 20px;
  text-align: center;
}

h1 {
  margin: 0;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  margin-top: 0.8rem;
  display: none;
  background-color: #333;
  z-index: 1;
}

.dropdown-content div {
  color: #eee;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content div:hover {
  background-color: #555;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Content styles */
.home-content {
  margin-top: 6rem;
  padding: 2rem;
  padding-right: 8rem;
  padding-left: 8rem;
  text-align: center;
  color: black;
}

label {
  padding: 10px;
  font-weight: bold;
}

.dropdown-button {
  color: #fff;
  background-color: #333;
  padding: 12px 16px;
  cursor: pointer;
}

.dropdown-content div {
  position: relative;
}

.dropdown-content div #product-wrapper {
  position: absolute;
  top: 0;
  left: 100%;
  display: none;
  padding: 12px 16px;
  background-color: #605d5d;
  text-decoration: none;
  color: #fff;
  z-index: 1;
}

.dropdown-content div:hover #product-wrapper {
  display: block;
}

.dropdown-content div #product-wrapper a {
  display: block;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
}

.dropdown-content div #product-wrapper a:hover {
  background-color: #839096;
}

.hero {
  font-size: 3.5rem;
}

