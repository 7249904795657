body {
  font-family: Arial, sans-serif;
  color: #fff;
  margin: 0;
  padding: 0;
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url(../images/plaza.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.logo {
  position: absolute;
  top: 12px;
  left: 12px;
  max-width: 16rem;
}

/* Header styles */
header {
  margin-top: 6rem;
  padding: 20px;
  text-align: center;
}

h1 {
  margin: 0;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  margin-top: 0.8rem;
  display: none;
  background-color: #333;
  z-index: 1;
}

.dropdown-content div {
  color: #eee;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content div:hover {
  background-color: #555;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Content styles */
.content {
  background-color: #fff;
  margin-top: 6rem;
  padding: 2rem;
  text-align: center;
  color: black;
}

label {
  padding: 10px;
  font-weight: bold;
}

.input {
  width: 30%;
  float: left;
  text-align: right;
  padding-top: 1rem;
}

.selection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-height: 50vh;
  overflow: auto;
  width: 70%;
  float: right;
  margin: 0, auto;
}

.submit {
  clear: both;
  width: 100%;
  margin-top: 10px;
  padding-top: 30px;
}

input {
  margin-bottom: 4px;
  padding: 10px;
  border-radius: 6px;
  text-align: center;
}

button {
  display: inline-block;
  padding: 1em 2em;
  line-height: 1;
  letter-spacing: 0.04em;
  font-size: 0.9rem;
  font-weight: bold;
  text-decoration: none !important;
  color: #fff;
  background-color: #5886c2;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  margin-bottom: 4px;
}

button:hover:not(.clicked) {
  background-color: #0f5996;
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 30px;
  padding: 1em 2em;
  font-size: 0.9rem;
  color: #fff;
  background-color: #5886c2;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
  margin: 2px;
  max-width: 50%;
}

.card:hover:not(.clicked) {
  background-color: #5886c2;
}

.deselected {
  background-color: #7e8b91;
}

.hero {
  font-size: 3.5rem;
}

.tooltip-container .tooltip {
  position: fixed;
  z-index: 9999;
  background-color: #fff;
  color: black;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid black;
  border-width: 1px;
  font-size: 12px;
  white-space: nowrap;
}

@media only screen and (max-width: 768px) {
  .content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
  }
  .selection {
    width: 100%;
    height: auto;
    margin-left: 2rem;
  }
  .hero {
    font-size: 2rem;
  }
  .input {
    text-align: left;
  }
  .card {
    width: 100px;
  }
}
